<template>
  <div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {

    }
  },
  mounted() {
    // 登入後，吃系統設定對應角色跳轉頁面
    let pathConfig = this.organizationThemeConfig?.dashboard?.default_path
    console.log(pathConfig)
    // 如果沒有設定 預設是首頁
    if (!pathConfig) {
      this.$router.push({
        name: "DashboardHome",
        params: { org_code: this.$route.params.org_code },
      });
      return
    }
    let path = '/' + this.$route.params.org_code + pathConfig.default
    const roleArray = Object.keys(pathConfig.roles)
    if (roleArray.includes(this.role)) {
      path = '/' + this.$route.params.org_code + pathConfig.roles[this.role]
    }
    this.$router.push(path)
  },
  computed: {
    ...mapState("general", {
      organizationThemeConfig: (state) => state.organizationThemeConfig,
    }),
    ...mapGetters('general', ['role']),
  }
}
</script>
